<style lang="less" scoped>
  .todo-card {
    padding: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
  }
</style>

<template>
  <div class="todo-block-list">
    <Divider dashed style="font-size: 14px;font-weight: normal;color: #999;margin: 0;">{{type === 'apply' ? '当前请假情况' : '年度请假情况'}}</Divider>
    <div v-for="item in dataList" class="todo-item" :key="item.id" @click.capture="$emit('item-click', item)">
      <div class="todo-card">
        <Row class-name="follow" type="flex" justify="space-between" align="top">
          <i-col style="flex: 1;">
            <div class="title">
              <span>{{ item.workerName }}</span>
              <span> - </span>
              <span>
                {{ item.status === 'end' ? item.realType : item.type }}
              </span>
            </div>
          </i-col>
          <i-col>
            <Tag size="large" v-if="item.isInvalid" color="red" class="noborder">已作废</Tag>
            <Tag size="large" v-else-if="item.status === 'end'" color="blue" class="noborder">已销假</Tag>
            <Tag size="large" v-else color="orange" class="noborder">未销假</Tag>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col v-if="item.status === 'end'" style="flex: 1;">
            <span class="field">时间:</span>
            <span class="value">{{ item.startTime || ' - ' }} 至 {{ item.endTime || ' - ' }}</span>
          </i-col>
          <i-col v-else style="flex: 1;">
            <span class="field">时间:</span>
            <span class="value">{{ item.realStartTime || ' - ' }} 至 {{ item.realEndTime || ' - ' }}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">
            <span class="field">事由:</span>
            <span class="value">{{ item.status === 'end' ? item.realReson : item.reson }}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <Tag size="large" color="geekblue" class="noborder">{{ item.status === 'end' ? item.realTimeLong : item.timeLong }}</Tag>
          </i-col>
          <i-col>
            <Tag size="large" class="noborder">{{type === 'apply' ? '当前请假情况' : '年度请假情况'}}</Tag>
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, default: 'apply' },
    dataList: { type: Array, default: () => [] },
    moduleName: { type: String, default: 'VacateApply' }
  }
}
</script>
