<style lang="less" scoped>
  .todo-card {
    padding: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
  }
</style>

<template>
  <div class="todo-block-list" v-if="available && dataList.length">
    <Divider dashed style="font-size: 14px;font-weight: normal;color: #999;margin: 0;">采购</Divider>
    <div v-for="item in dataList" class="todo-item" :key="item.id" @click.capture="itemClick(item)">
      <div class="todo-card">
        <Row class-name="follow" type="flex" justify="space-between" align="top">
          <i-col style="flex: 1;">
            <div class="title">{{ item.orgName }} - {{ item.goodsName }}</div>
          </i-col>
          <i-col>
            <Tag size="large" color="blue" class="noborder">{{ item.statusText }}</Tag>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">{{item.code || '-'}}</i-col>
          <i-col style="flex: 1;">
            <span class="field">单价:</span>
            <span class="value">{{(item.price !== null ? (item.price + '元') : '-')}}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">
            <span class="field">数量:</span>
            <span class="value">{{item.num !== null ? (item.num) : '-'}}</span>
          </i-col>
          <i-col style="flex: 1;">
            <span class="field">总价:</span>
            <span class="value">{{item.total !== null ? (item.total + '元') : '-'}}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col></i-col>
          <i-col>
            <Tag size="large" class="noborder">采购</Tag>
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
export default {
  isModule: true,
  name: 'Purchase',
  title: '采购',
  data () {
    return {
      dataList: [],
      status: {
        loaded: false,
        error: null,
        loading: false
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['internalcontrol.purchase.handle-get']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    itemClick (item) {
      this.$router.push({
        path: '/internalcontrol/purchase/details',
        query: {
          id: item.purchaseBatchId,
          type: 'handle'
        }
      })
    },
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        this.dataList = await request('/internalcontrol/purchase_batch/detail/by_auth?isUpcoming=1', 'get')
        this.status.loading = false
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loading = false
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', 'Purchase', this)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
