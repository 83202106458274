<style scoped></style>

<template>
  <Alert show-icon type="error" v-if="error">
    <div @click="$emit('click')">{{ title }}加载失败</div>
    <template slot="desc">
      <div @click="$emit('click')">点击重新加载</div>
      <div @click="$emit('click')">{{ error.message }}</div>
    </template>
  </Alert>
</template>

<script>
export default {
  props: {
    title: { type: String, default: null },
    error: { type: [Object, Error], default: null }
  }
}
</script>
