<style scoped></style>

<template>
  <Vacate @item-click="itemClick" v-if="available && dataList.length" :data-list="dataList" module-name="MyVacate" type="my"/>
</template>

<script>
import { request } from '@/api'
import Vacate from './index'
export default {
  name: 'MyVacate',
  title: '年度请假情况',
  isModule: true,
  components: { Vacate },
  data () {
    return {
      dataList: [],
      status: {
        loading: false,
        loaded: false,
        error: null
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['hrms.ask_leave.my-get']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    itemClick () {
      this.$router.push({ path: '/hrms/ask_leave/record' })
    },
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        const res = await request('/basis/ask_leave/my', 'get', {
          minSearchTime: this.$datetime.format(new Date(), 'Y-M-D 00:00:00')
        })
        this.dataList = res
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', 'MyVacate', this)
      this.status.loading = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
