<style lang="less" scoped>
  .todo-card {
    padding: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
  }
  .row + .row {
    margin-top: 10px;
  }
</style>

<template>
  <div class="todo-block-list" v-if="available && dataList.length">
    <Divider dashed style="font-size: 14px;font-weight: normal;color: #999;margin: 0;">维修</Divider>
    <div v-for="item in dataList" class="todo-item" :key="item.id" @click.capture="itemClick(item)">
      <div class="todo-card">
        <Row class-name="follow" type="flex" justify="space-between" align="top">
          <i-col style="flex: 1;">
            <div class="title">{{ item.applyOrgName }} - {{ item.userName }}</div>
          </i-col>
          <i-col>
            <Tag size="large" color="blue" class="noborder">{{ item.statusText }}</Tag>
          </i-col>
        </Row>

        <div class="row" v-for="(assets, index) in item.detailList" :key="assets.id">
          <Row class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;">
              {{item.detailList.length > 1 ? ('（资产' + (index + 1) + '）') : ''}}
              {{assets.assets ? assets.assets.name : assets.assetsName}}
            </i-col>
          </Row>
          <template v-if="assets.assets">
            <Row class-name="follow" type="flex" justify="space-between" align="middle">
              <i-col style="flex: 1;">
                <span class="field">资产编码:</span>
                <span class="value">{{assets.assets.code}}</span>
              </i-col>
              <i-col style="flex: 1;">
                <span class="field">价值:</span>
                <span class="value">{{assets.assets.jz !== null ? (assets.assets.jz + '元') : '-'}}</span>
              </i-col>
            </Row>
            <Row class-name="follow" type="flex" justify="space-between" align="middle">
              <i-col style="flex: 1;">
                <span class="field">管理机构:</span>
                <span class="value">{{assets.assets.manageOrgName}}</span>
              </i-col>
              <i-col style="flex: 1;">
                <span class="field">使用机构:</span>
                <span class="value">{{assets.assets.useOrgName}}</span>
              </i-col>
            </Row>
          </template>
          <Row v-else class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;" v-if="assets.evaluateType !== 'can_not'">
              <span class="field">价值:</span>
              <span class="value">{{assets.jz ? (assets.jz + '元') : '-'}}</span>
            </i-col>
            <i-col style="flex: 1;" v-else>
              <span class="field">价值:</span>
              <span class="value">无法估值</span>
            </i-col>
          </Row>
        </div>

        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <span class="field">编码:</span>
            <span class="value">WXSQ{{item.id}}</span>
          </i-col>
        </Row>

        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <span class="field">维修厂家:</span>
            <span class="value">{{item.repairCmp}}</span>
          </i-col>
          <i-col>
            <span class="field">预估维修金额:</span>
            <span class="value">{{item.repairJe}}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <span class="field">关联预算:</span>
            <span class="value">{{item.budgetDetail ? (item.budgetDetail.code + ' ' + item.budgetDetail.goodsName) : '无'}}</span>
          </i-col>
          <i-col>
            <span class="field">申请理由:</span>
            <span class="value">{{item.reason}}</span>
          </i-col>
        </Row>

        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <Tag size="large" :color="item.typeData.color" class="noborder">{{item.typeData.label}}</Tag>
          </i-col>
          <i-col>
            <Tag size="large" class="noborder">维修</Tag>
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
export default {
  isModule: true,
  name: 'Repair',
  title: '维修',
  data () {
    return {
      dataList: [],
      status: {
        loaded: false,
        error: null,
        loading: false
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['internalcontrol.assets.manage-repair'] || this.$authFunsProxyNoRoute['internalcontrol.repair.apply_record-getMy'] || this.$authFunsProxyNoRoute['internalcontrol.repair.apply-base']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    itemClick () {
      this.$router.push({
        path: '/internalcontrol/repair/apply'
      })
    },
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        const dataList = await request('/internalcontrol/assets_repair_apply/by_auth', 'get', {
          statusActionRecord: 1,
          isNotEnd: 1
        })

        const types = {
					mini: { label: '价值2000元以下', color: 'green' },
					norm: { label: '价值2000元以上', color: 'blue' }
				}

        dataList.forEach(item => {
          item.typeData = types[item.type]
        })
        
        this.dataList = dataList.filter(v => v.actions && v.actions.filter(v1 => v1.key !== 'back_status_switch').length > 0)
        this.status.loading = false
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loading = false
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', 'Repair', this)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
