<style scoped></style>

<template>
  <Vacate @item-click="itemClick" v-if="available && dataList.length" :data-list="dataList" module-name="VacateApply" type="apply" />
</template>

<script>
import { request } from '@/api'
import Vacate from './index'
export default {
  name: 'VacateApply',
  title: '当前请假情况',
  isModule: true,
  components: { Vacate },
  data () {
    return {
      dataList: [],
      status: {
        loading: false,
        loaded: false,
        error: null
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['hrms.ask_leave.apply_assign-base'] && this.$authFunsProxyNoRoute['hrms.ask_leave.record-get'] && this.$authFunsProxyNoRoute['hrms.ask_leave.record-detail']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    itemClick () {
      this.$router.push({ path: '/hrms/ask_leave/my' })
    },
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        const res = await request('/basis/ask_leave?status=plan&isInvalid=0', 'get')
        const apply = await request('/basis/ask_leave_apply?isDoing=1', 'get')
        this.dataList = [...res, ...apply]
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', 'VacateApply', this)
      this.status.loading = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
