<style lang="less" scoped>
  .todo-card {
    padding: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
  }
</style>

<template>
  <div class="todo-block-list" v-if="available && dataList.length">
    <Divider dashed style="font-size: 14px;font-weight: normal;color: #999;margin: 0;">{{type === 'doc' ? '公文处理' : '会议文件'}}</Divider>
    <div v-for="item in dataList" class="todo-item" @click.capture="itemClick(item)" :key="item.id">
      <div class="todo-card">
        <Row class-name="follow" type="flex" justify="space-between" align="top">
          <i-col style="flex: 1;">
            <div class="title">{{item.officialDocumentTitle}}</div>
          </i-col>
          <i-col>
            <Tag size="large" v-if="item.status === 'wait'" color="orange" class="noborder">待办</Tag>
            <Tag size="large" v-else color="blue" class="noborder">已办</Tag>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">
            <span class="field">编码:</span>
            <span class="value">{{item.officialDocumentCode}}</span>
          </i-col>
          <i-col style="flex: 1;">
            <span class="field">来文日期:</span>
            <span class="value">{{(item.officialDocumentSendTime || '').substr(0, 10)}}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">
            <span class="field">来文单位:</span>
            <span class="value">{{item.sendOrgName}}</span>
          </i-col>
          <i-col style="flex: 1;">
            <span class="field">来文号:</span>
            <span class="value">{{item.officialDocumentSendCode}}</span>
          </i-col>
        </Row>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <Tag size="large" color="geekblue" class="noborder">共{{item.officialDocumentNum || 0}}份</Tag>
          </i-col>
          <i-col>
            <Tag size="large" class="noborder">{{type === 'doc' ? '公文处理' : '会议文件'}}</Tag>
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
export default {
  props: {
    type: { type: String, default: 'doc' },
    moduleName: { type: String, default: 'DocHandle' }
  },
  data () {
    return {
      dataList: [],
      status: {
        loaded: false,
        error: null,
        loading: false
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['hrms.doc.handle-get'] || this.$authFunsProxyNoRoute['hrms.doc.handle_hywj-detail']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        this.dataList = await request('/basis/official_document_handle/my', 'get', {
          status: 'wait',
          officialDocumentType: this.type
        })
        this.status.loading = false
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loading = false
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', this.moduleName, this)
    },
    itemClick (item) {
      this.$router.push({
        path: '/hrms/doc/detail',
        query: {
          id: item.officialDocumentId,
          type: this.type,
          handle: 1
        }
      })
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
