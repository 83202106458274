<template>
  <Doc type="doc" ref="doc" v-on="$listeners" module-name="DocHandle" />
</template>

<script>
import Doc from './index.vue'
export default {
  isModule: true,
  name: 'DocHandle',
  title: '公文处理',
  components: { Doc },
  methods: {
    loadData () {
      return this.$refs.doc.loadData()
    }
  }
}
</script>
