<template>
  <Doc type="hywj" ref="doc" v-on="$listeners" module-name="DocHywj" />
</template>

<script>
import Doc from './index.vue'
export default {
  isModule: true,
  name: 'DocHywj',
  title: '会议文件',
  components: { Doc },
  methods: {
    loadData () {
      return this.$refs.doc.loadData()
    }
  }
}
</script>
