<style lang="less" scoped>
  .todo-card {
    padding: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
  }
  .row {
    margin-top: 10px;
  }
</style>

<template>
  <div class="todo-block-list" v-if="available && dataList.length">
    <Divider dashed style="font-size: 14px;font-weight: normal;color: #999;margin: 0;">报废</Divider>
    <div v-for="item in dataList" class="todo-item" :key="item.id" @click.capture="itemClick(item)">
      <div class="todo-card">
        <Row class-name="follow" type="flex" justify="space-between" align="top">
          <i-col style="flex: 1;">
            <div class="title">BFSQ{{ item.id }}</div>
          </i-col>
          <i-col>
            <Tag size="large" color="blue" class="noborder">{{ item.statusText }}</Tag>
          </i-col>
        </Row>

        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">
            <span class="field">申请人:</span>
            <span class="value">{{item.userName}}</span>
          </i-col>
          <i-col style="flex: 1;">
            <span class="field">申请科室:</span>
            <span class="value">{{item.applyOrgName}}</span>
          </i-col>
        </Row>

        <div class="row" v-for="(assets, index) in item.detailList" :key="assets.id">
          <Row class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;">
              {{item.detailList.length > 1 ? ('（资产' + (index + 1) + '）') : ''}}
              {{assets.assets ? assets.assets.name : assets.assetsName}}
            </i-col>
          </Row>
          <Row class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;">
              <span class="field">资产编码:</span>
              <span class="value">{{assets.assets.code}}</span>
            </i-col>
            <i-col style="flex: 1;">
              <span class="field">价值:</span>
              <span class="value">{{assets.assets.jz !== null ? (assets.assets.jz + '元') : '-'}}</span>
            </i-col>
          </Row>
          <Row class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;">
              <span class="field">管理机构:</span>
              <span class="value">{{assets.assets.manageOrgName}}</span>
            </i-col>
            <i-col style="flex: 1;">
              <span class="field">使用机构:</span>
              <span class="value">{{assets.assets.useOrgName}}</span>
            </i-col>
          </Row>
        </div>

        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col style="flex: 1;">
            <span class="field">说明:</span>
            <span class="value">{{item.remark}}</span>
          </i-col>
          <i-col style="flex: 1;">
            <span class="field">残余价值:</span>
            <span class="value">{{item.jz ? (item.jz + '元') : '-'}}</span>
          </i-col>
        </Row>

        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <Tag v-if="item.type === 'norm'" size="large" color="geekblue" class="noborder">正常报废</Tag>
            <Tag v-else size="large" color="volcano" class="noborder">异常报废</Tag>
          </i-col>
          <i-col>
            <Tag size="large" class="noborder">报废</Tag>
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
export default {
  isModule: true,
  name: 'Scrap',
  title: '报错',
  data () {
    return {
      dataList: [],
      status: {
        loaded: false,
        error: null,
        loading: false
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['internalcontrol.assets.manage-scrap'] || this.$authFunsProxyNoRoute['internalcontrol.scrap.apply-base']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    itemClick () {
      this.$router.push({
        path: '/internalcontrol/scrap/apply'
      })
    },
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        this.dataList = await request('/internalcontrol/assets_scrap_apply/by_auth?statusActionRecord=1&isNotEnd=1', 'get')
        this.status.loading = false
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loading = false
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', 'Scrap', this)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
