<style lang="less" scoped>
  .todo-card {
    padding: 10px;
  }
  .title {
    font-size: 14px;
    font-weight: 800;
  }
  .row + .row {
    margin-top: 10px;
  }
</style>

<template>
  <div class="todo-block-list" v-if="available && dataList.length">
    <Divider dashed style="font-size: 14px;font-weight: normal;color: #999;margin: 0;">采购方式</Divider>
    <div v-for="item in dataList" class="todo-item" :key="item.id" @click.capture="itemClick(item)">
      <div class="todo-card">
        <Row class-name="follow" type="flex" justify="space-between" align="top">
          <i-col style="flex: 1;">
            <div class="title">{{ item.busOrgName }}</div>
          </i-col>
          <i-col>
            <Tag size="large" color="blue" class="noborder">{{ item.statusText }}</Tag>
          </i-col>
        </Row>
        <div v-for="(detail, index) in item.detailList" :key="detail.id" class="row">
          <Row class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;">
              <span class="field">采购物品{{ index + 1 }}:</span>
              <span class="value">{{ detail.purchaseBatchDetail ? detail.purchaseBatchDetail.goodsName : '-' }}</span>
            </i-col>
            <i-col style="flex: 1;">
              <span class="field">申报科室:</span>
              <span class="value">{{ detail.purchaseBatchDetail ? detail.purchaseBatchDetail.orgName : '-' }}</span>
            </i-col>
          </Row>
          <Row class-name="follow" type="flex" justify="space-between" align="middle">
            <i-col style="flex: 1;">
              <span class="field">单价:</span>
              <span class="value">{{detail.price !== null ? (detail.price + '元') : '-'}}</span>
            </i-col>
            <i-col style="flex: 1;">
              <span class="field">数量:</span>
              <span class="value">{{detail.num !== null ? (detail.num) : '-'}}</span>
            </i-col>
            <i-col style="flex: 1;">
              <span class="field">总价:</span>
              <span class="value">{{detail.total !== null ? (detail.total + '元') : '-'}}</span>
            </i-col>
          </Row>
        </div>
        <Row class-name="follow" type="flex" justify="space-between" align="middle">
          <i-col>
            <Tag size="large" :color="item.typeData.color" class="noborder">{{item.typeData.label}}</Tag>
          </i-col>
          <i-col>
            <Tag size="large" class="noborder">采购方式</Tag>
          </i-col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
export default {
  isModule: true,
  name: 'PurchaseMethod',
  title: '采购方式',
  data () {
    return {
      dataList: [],
      status: {
        loaded: false,
        error: null,
        loading: false
      }
    }
  },
  computed: {
    available () {
      return this.$authFunsProxyNoRoute['internalcontrol.purchase_method.handle-get']
    }
  },
  watch: {
    available () {
      this.loadData()
    }
  },
  methods: {
    itemClick (item) {
      this.$router.push({
        path: '/internalcontrol/purchase_method/details',
        query: {
          id: item.purchaseMethodBatchId,
          type: 'handle'
        }
      })
    },
    async loadData () {
      if (!this.available) {
        return
      }
      try {
        this.status.loading = true
        const dataList = await request('/internalcontrol/purchase_method_batch/detail/by_auth?isUpcoming=1', 'get')

        const types = {
					mini: { label: '金额2000元以下', color: 'green' },
					small: { label: '金额2000元以上', color: 'blue' },
					big: { label: '大型采购', color: 'purple' }
				}
        dataList.forEach(item => {
          item.typeData = types[item.type]
        })
        
        this.dataList = dataList
        this.status.loading = false
        this.status.loaded = true
        this.status.error = null
      } catch (e) {
        this.status.loading = false
        this.status.loaded = false
        this.status.error = e
      }
      this.$emit('loading', 'PurchaseMethod', this)
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
