<style lang="less">
.todo-page {
  padding-bottom: 5px;
  .todo-block-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 5px;
    .todo-item {
      width: 50%;
      padding: 5px;
      .todo-card {
        cursor: pointer;
        background-color: #FFF;
        border-radius: 5px;
        border: 1px dashed #EEE;
        transition: all .3s;
        &:hover {
          border-style: solid;
          border-color: #5cadff;
        }
        .ivu-row.follow + .ivu-row.follow {
          margin-top: 5px;
          padding-top: 5px;
          border-top: 1px solid #F8F8F8;
        }
        span.field {
          color: #999;
          margin-right: 5px;
        }
        span.value {
          color: #657180;
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
  .sys-title {
    padding: 0 10px;
    margin: 10px 10px 5px 10px;
    background-color: #FFF;
    border-radius: 5px;
    min-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 10px;
    z-index: 1;
    border: 1px solid #EEE;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .2);
  }
  .sys-label {
    font-size: 15px;
    font-weight: 800;
    .status {
      margin-left: 10px;
      font-size: 12px;
      font-weight: normal;
    }
  }
</style>

<template>
  <div class="scrollbar todo-page" style="overflow: auto;">
    <div class="sys-block" v-for="sys in modules" :key="sys.key">
      <div class="sys-title">
        <div class="sys-label">
          {{ sys.label }}待办事项
          <span v-if="!total[sys.key]" class="status">暂无待办</span>
          <span v-else class="status">共 {{total[sys.key]}} 条</span>
        </div>
        <Button icon="md-refresh" :loading="loading[sys]" @click="reLoad(sys.key)">刷新</Button>
      </div>
      <div v-for="cmp in sys.$components" :key="cmp.name">
        <component :ref="sys.key + '-' + cmp.name" @loading="(name, vm) => onLoading(sys.key, name, vm)" :is="cmp"></component>
        <todo-error style="margin: 5px 10px;cursor: pointer;" @click="loadData(sys.key + '-' + cmp.name)" :title="cmp.title" :error="status[sys.key][cmp.name].error" v-if="status[sys.key][cmp.name].error" />
      </div>
    </div>
  </div>
</template>

<script>
import { otherSys } from '@/sys'
import TodoError from './error.vue'

const modules = otherSys.filter(sys => sys.todo).map(sys => {
  let components = []

  if (sys.key === 'hrms') {
    components = require('hrms/src/modules/todo/index').default
  } else if (sys.key === 'internalcontrol') {
    components = require('internalcontrol/src/modules/todo/index').default
  }

  return {
    label: sys.label,
    key: sys.key,
    $components: components
  }
})

let updateTotalTimer = null

export default {
  components: { TodoError },
  data () {
    let status = {}
    let total = {}
    let loading = {}
    modules.forEach(item => {
      let mStatus = {}
      item.$components.forEach(cmp => {
        mStatus[cmp.name] = {
          total: 0,
          loaded: false,
          error: null
        }
      })
      status[item.key] = mStatus
      total[item.key] = 0
      loading[item.key] = false
    })
    return {
      status,
      total,
      loading
    }
  },
  computed: {
    modules () {
      return modules
    }
  },
  methods: {
    reLoad (sys) {
      this.loading[sys] = true
      try {
        modules.find(v => v.key === sys).$components.forEach(cmp => {
          const ref = this.$refs[sys + '-' + cmp.name]
          if (ref) {
            if (typeof ref[0].loadData !== 'function') {
              return console.warn(sys, '.', cmp.name, 'loadData no exist', ref[0])
            }
            ref[0].loadData()
          }
        })
      } catch (e) {
        console.error(e)
      }
      this.loading[sys] = false
    },
    loadData (ref) {
      const cmp = this.$refs[ref]
      if (cmp && cmp.length) {
        cmp[0].loadData()
      }
    },
    onLoading (key, name, vm) {
      this.status[key][name].total = vm.dataList.length
      this.status[key][name].loaded = vm.status.loaded
      this.status[key][name].error = vm.status.error
      this.updateTotal()
    },
    updateTotal () {
      clearTimeout(updateTotalTimer)
      updateTotalTimer = setTimeout(() => {
        modules.forEach(sys => {
          this.total[sys.key] = sys.$components.reduce((c, d) => {
            return c + this.status[sys.key][d.name].total
          }, 0)
        })
      }, 500)
    }
  }
}
</script>
